import ChatbotTest from './ChatbotTest';
//import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
//import Login from "./Login";
//import SpeechToText from './SpeechToText';


function App() {
  return (
    <>
    <ChatbotTest />
    {/*
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/chatbot" element={<ChatbotWeb />} />
      </Routes>
    </Router>*/}
    </>
  );
}

export default App;