import axios from 'axios';

//export const urlApi = 'http://localhost:8002';
export const urlApi = 'https://mara-ml-api-qyo82.ondigitalocean.app';

const api = axios.create({
  //baseURL: 'http://localhost:8002/', 
  baseURL: 'https://mara-ml-api-qyo82.ondigitalocean.app/', 
  headers: {
    'Content-Type': 'application/json',
  }
});

export const fetchWithAuth = (url, authToken) => {
  return api.get(url, {
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  });
};

export const postWithAuth = (url, data, authToken) => {
  return api.post(url, data, {
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  });
};

export const putWithAuth = (url, data, authToken) => {
  return api.put(url, data, {
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  });
};

export const deleteWithAuth = (url, authToken) => {
  return api.delete(url, {
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  });
};
