import React from 'react';


const RenderAudioAnimation = () => (
    <div className="audio-bars">
        {Array.from({ length: 11 }).map((_, index) => (
            <div key={index} className="audio-bar"></div>
        ))}
    </div>
);

export default RenderAudioAnimation