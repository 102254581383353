import React, { useState, useEffect, useRef } from "react";
import { marked } from "marked";
import renderAudioAnimation from "./components/RenderAudioAnimation";
import { postWithAuth, deleteWithAuth, urlApi } from "./api/apiService";
//import user_image from './assets/images/user_image.png'
//import bot_image from './assets/images/mundl_logo.jpeg'
//import mara from './assets/images/mara.png'
//import maraVideo from './assets/videos/mara.mp4'
import maraSpeak from "./assets/images/speaking.gif";
import maraNotSpeak from "./assets/images/not_speaking.gif";
import { AiOutlineSend } from "react-icons/ai";
import { MdKeyboardVoice } from "react-icons/md";
import MLGroup from './assets/images/mlgroup_background.PNG'
import { v4 as uuidv4 } from 'uuid';

const ChatbotTest = () => {
  const initialMessage = {
    id: uuidv4(),
    sender: "bot",
    text: `Hallo, wie kann ich Ihnen helfen? 
              <br> Ich interessiere mich für...
              <br>
              <ul style="list-style-type: square; padding-left: 0.5rem;">
                <li>KI-Lösungen</li>
                <li>High-Level Projekte</li>
                <li>Kundenbeziehungen und Zielmärkte</li>
                <li>IAM - Identity and Access Management</li>
                <li>Regionales Marktmonitoring</li>
                <li>ZOHO-Lösungen als sofort einsetzbare Komplettlösung ohne IT</li>
                <li>Standortmanagement und Mehr</li>
                <li>Target Matrix - Adressierbares Marktpotential</li>
                <li>Industrial Internet of Things (IIoT) Lösungen</li>
                <li>Qualitätsmanagement - ein entscheidender Wettbewerbsvorteil</li>
                <li>Innovationsberatung</li>
                <li>Sensor Integration & Data Monitoring</li>
              </ul>
              <br> Ihre Mara ML
`,
  };

  const mara = `https://mara-chatbot-aq6wg.ondigitalocean.app/mara.png`;
  const user_image = `https://mara-chatbot-aq6wg.ondigitalocean.app/user_image.png`;
  const authToken = "TSZMIENvcnBvcmF0ZSBHcm91cCwgQUcsIFN5c3RlbXMsIA==";

  const [messages, setMessages] = useState([initialMessage]);
  const [userInput, setUserInput] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const messagesEndRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitel] = useState(
    "Hallo, ich bin Mara ML. Wie kann ich Ihnen helfen?"
  );
  const [count, setCount] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const inputRef = useRef(null);
  const [sessionID, setSessionID] = useState("");
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [language, setLanguage] = useState("de-De");
  const [audioUrl, setAudioUrl] = useState(null);
  const audioStartUrl = `${urlApi}/audio_files/audioStart.mp3`;
  const audioEndUrl = `${urlApi}/audio_files/audioEnd.mp3`;
  const audioRef = useRef(null);
  const [isListening, setIsListening] = useState(false);

  useEffect(() => {
    setSessionID(localStorage.getItem("session_id"));
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);


  useEffect(() => {
    if (audioUrl) {
      playAudio(audioUrl);
    }
  }, [audioUrl]);

  const playAudio = async (url) => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current = null;
    }
    setIsSpeaking(true);
    const uniqueUrl = `${url}?t=${new Date().getTime()}`;
    const audioElement = new Audio(uniqueUrl);
    audioRef.current = audioElement;
    audioRef.current = audioElement;
    try {
      await audioElement.play();
      audioElement.onended = async () => {
        const filename = url.split('/').pop();
        if (!url.includes("audioStart") && !url.includes("audioEnd")) {
          try {
            const response = await deleteWithAuth(`/api/v1/audio/${filename}`, authToken);
            console.log(response.data);
          } catch (error) {
            console.error('Error deleting audio file', error);
          }
        }
        setAudioUrl(null);
        audioRef.current = null;
        setIsSpeaking(false);
      };
    } catch (error) {
      console.error('Error playing audio:', error);
      setAudioUrl(null);
      audioRef.current = null;
      setIsSpeaking(false);
    }
  };

  /*
    useEffect(() => {
      const handleAudioCleanup = async () => {
        if (audioRef.current) {
          audioRef.current.pause();
          const filename = audioUrl.split('/').pop();
          try {
            const response = await deleteWithAuth(`/api/v1/audio/${filename}`, authToken);
            console.log(response.data);
          } catch (error) {
            console.error('Error deleting audio file', error);
          }
        }
        setAudioUrl(null);
        audioRef.current = null;
        setIsSpeaking(false);
      };
  
      return () => {
        handleAudioCleanup();
      };
    }, [audioUrl, authToken]);
  
    const handleAudioCleanup = async () => {
      if (audioRef.current) {
        audioRef.current.pause();
        const filename = audioUrl.split('/').pop();
        try {
          const response = await deleteWithAuth(`/api/v1/audio/${filename}`, authToken);
          console.log(response.data);
        } catch (error) {
          console.error('Error deleting audio file', error);
        }
      }
      audioRef.current = null;
      setIsSpeaking(false);
    };
  
    const speakResponse = async () => {
      //await handleAudioCleanup();
      if (audioUrl) {
        setIsSpeaking(true);
        const audioElement = new Audio(audioUrl);
        audioRef.current = audioElement;
        audioElement.play();
        audioElement.onended = async () => {
          const filename = audioUrl.split('/').pop();
          try {
            const response = await deleteWithAuth(`/api/v1/audio/${filename}`, authToken);
            console.log(response.data);
          } catch (error) {
            console.error('Error deleting audio file', error);
          }
          setAudioUrl(null);
          audioRef.current = null;
          setIsSpeaking(false);
        };
      }
    };*/

  const handleCloseChat = async () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      const filename = audioUrl ? audioUrl.split('/').pop() : null;
      if (filename && !audioUrl.includes("audioStart") && !audioUrl.includes("audioEnd")) {
        try {
          const response = await deleteWithAuth(`/api/v1/audio/${filename}`, authToken);
          console.log(response.data);
        } catch (error) {
          console.error('Error deleting audio file', error);
        }
      }
      audioRef.current = null;
      setAudioUrl(null);
      setIsSpeaking(false);
    }
    setIsVisible(false);
  };


  const handleOpenCaht = () => {
    setIsVisible(true);
    if (audioStartUrl) {
      playAudio(audioStartUrl);
    }
  };

  const speechRecognition = useRef(
    new (window.SpeechRecognition || window.webkitSpeechRecognition)()
  );
  speechRecognition.continuous = true;
  speechRecognition.interimResults = true;
  speechRecognition.lang = language;

  speechRecognition.onstart = () => console.log("Voice recognition is activated");
  speechRecognition.onend = () => console.log("Speech recognition stopped");

  const handleSendMessage = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setIsListening(false);
    speechRecognition.current.stop();
    const messageToSend = inputRef.current.value;
    if (!messageToSend.trim()) return;

    if (isLoading) return;

    if (count >= 12) {
      setMessages((messages) => [
        ...messages,
        {
          id: uuidv4(),
          sender: "bot",
          text: `Kommen Sie bei Fragen gerne auf uns zu:
            <br/><br/>Wir freuen uns auf den persönlichen Kontakt mit Ihnen.
            <br/><br/><strong>M&L Aktiengesellschaft</strong>
            <br/><strong>Schwarzwaldstrasse 122</strong>
            <br/><strong>60528 Frankfurt am Main</strong>
            <br/>
            <br/><a href="tel:+49699636320">T: +49 (0)69 963 632 - 0</a>
            <br/><a href="tel:+496996363210">F: +49 (0)69 963 632 - 10</a>
            <br/><a  href="mailto:kontakt@mlconsult.com">kontakt@mlconsult.com</a>         
`,
        },
      ]);
      if (audioEndUrl) {
        playAudio(audioEndUrl);
      }
      return;
    }

    setTitel("Mara ML");
    setIsLoading(true);
    setUserInput("");
    setMessages((messages) => [
      ...messages,
      { id: uuidv4(), sender: "user", text: messageToSend },
    ]);
    inputRef.current.blur();

    try {

      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        const filename = audioUrl ? audioUrl.split('/').pop() : null;
        if (filename && !audioUrl.includes("audioStart") && !audioUrl.includes("audioEnd")) {
          try {
            const response = await deleteWithAuth(`/api/v1/audio/${filename}`, authToken);
            console.log(response.data);
          } catch (error) {
            console.error('Error deleting audio file', error);
          }
        }
        audioRef.current = null;
        setAudioUrl(null);
        setIsSpeaking(false);
      }

      //const response = await axios.post('https://mara-ml-api-qyo82.ondigitalocean.app/api/v1/chat/',
      const response = await postWithAuth("/api/v1/chat/",
        {
          prompt: messageToSend,
          session_id: sessionID ? sessionID : "",
        },
        authToken
      );

      if (response.data.session_id && response.data.session_id !== sessionID) {
        localStorage.setItem("session_id", response.data.session_id);
        setSessionID(response.data.session_id);
      }

      setLanguage(response.data.language);
      setMessages((messages) => [
        ...messages,
        { id: uuidv4(), sender: "bot", text: response.data.response },
      ]);
      setCount(count + 1);

      setAudioUrl(response.data.audio_url);

    } catch (error) {
      console.error("Error:", error);
      setMessages((messages) => [
        ...messages,
        {
          id: uuidv4(),
          sender: "bot",
          text: "Aktuell konnte ich keine Daten empfangen. Bitte probieren Sie es später nochmal.<br><br> Ihre Mara ML",
        },
      ]);
      setCount(count + 1);
    } finally {
      setIsLoading(false);
    }
  };

  const createMarkup = (markdownText) => {
    return { __html: marked(markdownText) };
  };

  useEffect(() => {
    let timeoutId;

    speechRecognition.current.onresult = (event) => {
      const currentTranscript = Array.from(event.results)
        .map((result) => result[0].transcript)
        .join("");
      setUserInput(currentTranscript);

      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        if (isListening) {
          setIsListening(false);
          speechRecognition.current.stop();
          handleSendMessage({ preventDefault: () => { } });
          setIsListening(false);
        }
      }, 3000);
    };

    speechRecognition.current.onend = () => {
      if (isListening) {
        speechRecognition.current.start();
      }
    };
  }, [isListening]);


  const toggleListening = async () => {
    if (audioRef.current) {
      audioRef.current.pause();
      const filename = audioUrl.split('/').pop();
      try {
        const response = await deleteWithAuth(`/api/v1/audio/${filename}`, authToken);
        console.log(response.data);
      } catch (error) {
        console.error('Error deleting audio file', error);
      }
      audioRef.current = null;
      setAudioUrl(null);
      setIsSpeaking(false);
    }
    console.log("isListening", isListening);
    if (!isListening) {
      speechRecognition.current.lang = language;
      setIsListening(true);
      speechRecognition.current.start();
    } else {
      setIsListening(false);
      speechRecognition.current.stop();
      await handleSendMessage({ preventDefault: () => { } });
    }
  };


  return (
    <>
      <div className="tw-h-screen tw-justify-content-center d-flex tw-items-center" style={{ backgroundImage: `url(${MLGroup})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <div className="container tw-flex tw-flex-col tw-h-full tw-justify-center">
          <div
            className={`mundl100  ${isVisible ? "lg:tw-max-w-6xl" : "tw-max-w-md"
              } tw-z-20 tw-flex tw-flex-col tw-justify-between`}
          >
            {!isVisible && (
              <div className="mundl101 tw-flex tw-flex-col tw-items-center tw-justify-center">
                <div
                  className="mundl102 tw-text-white tw-text-xl tw-font-bold tw-py-3 tw-px-4 tw-rounded-t-lg tw-w-44 lg:tw-w-72 tw-text-center tw-shadow-lg"
                  style={{ backgroundColor: "#2edfba", fontFamily: "sans-serif" }}
                >
                  Hallo, ich bin Mara ML. <br />
                  Wie kann ich Ihnen helfen?
                </div>
                <img
                  src={mara}
                  alt="Chatbot"
                  className="mundl103 tw-w-44 tw-h-44 lg:tw-w-72 lg:tw-h-72 tw-object-cover tw-rounded-b-lg"
                />
                <button
                  className="mundl104 tw-mt-4 tw-mb-4 tw-text-white tw-text-xl tw-font-bold tw-py-2 tw-px-4 tw-w-44 tw-h-14 tw-flex tw-items-center tw-justify-center tw-border-2 tw-border-neutral-100 tw-shadow-md hover:tw-bg-blue-700 tw-transition-all"
                  onClick={handleOpenCaht}
                  style={{ backgroundColor: "#0000A2", fontFamily: "sans-serif" }}
                >
                  Chat
                </button>
                <button
                  className="mundl1055 tw-mb-8 tw-text-white tw-text-xl tw-font-bold tw-py-2 tw-px-4 tw-w-44 tw-h-14 tw-flex tw-items-center tw-justify-center tw-border-2 tw-border-neutral-100 tw-shadow-md hover:tw-bg-green-500 tw-transition-all"
                  onClick={() =>
                    window.open("https://www.mlconsult.com/kontakt.html", "_self")
                  }
                  style={{ backgroundColor: "#2edfba", fontFamily: "sans-serif" }}
                >
                  <span className="tw-mr-2">📧</span>
                  Kontakt
                </button>
              </div>
            )}
            {isVisible && (
              <div className="mundl105 tw-flex tw-flex-col md:tw-flex-row tw-w-full  lg:tw-max-w-6xl tw-overflow-hidden">
                {/*<img src={mara} alt="Mara ML" className="imgmara101 tw-rounded-l-xl tw-object-cover tw-h-full tw-border-4 tw-border-[#2edfba]" />*/}
                <div className="mundl106 tw-w-full md:tw-w-1/3 md:tw-h-1/3">
                  {isSpeaking ? (
                    <img
                      src={maraSpeak}
                      alt="Mara ML"
                      className="imgmara101 md:tw-rounded-l-xl tw-object-cover tw-w-full tw-h-44 md:tw-h-full tw-border-4 tw-border-[#2edfba] "
                    />
                  ) : (
                    <img
                      src={maraNotSpeak}
                      alt="Mara ML"
                      className="imgmara101 md:tw-rounded-l-xl tw-object-cover tw-w-full tw-h-44 md:tw-h-full tw-border-4 tw-border-[#2edfba] "
                    />
                  )}
                </div>
                <div className="mundl107 tw-card tw-w-full tw-shadow-lg tw-rounded-r-xl tw-max-w-2xl">
                  <div className="mundl108 tw-flex tw-justify-between tw-items-center tw-h-16 ">
                    <div
                      className="mundl109 tw-w-full tw-h-full tw-text-white tw-text-xl tw-p-2 tw-flex tw-justify-start tw-items-center tw-text-center"
                      style={{
                        backgroundColor: "#2edfba",
                        fontFamily: "sans-serif",
                      }}
                    >
                      {title}
                    </div>
                    <button
                      className="mundl110 tw-text-white tw-text-xl tw-h-full tw-w-14 tw-p-2 tw-flex tw-justify-center tw-items-center tw-border-2 tw-border-[#2edfba] tw-text-center"
                      onClick={handleCloseChat}
                      style={{
                        backgroundColor: "#0000A2",
                        fontFamily: "sans-serif",
                      }}
                    >
                      &times;
                    </button>
                  </div>
                  <div className="mundl111 tw-card-body tw-overflow-y-auto tw-p-3 tw-flex-grow tw-h-[340px] xl:tw-h-[375px] 2xl:tw-h-[600px] tw-bg-white">
                    {messages.map((message) => (
                      <div
                        key={message.id}
                        className={`mundl112 tw-flex tw-items-start tw-pb-2 ${message.sender === "user"
                          ? "tw-justify-end"
                          : "tw-justify-start"
                          }`}
                      >
                        {message.sender === "bot" && (
                          <img
                            src={mara}
                            alt="Bot"
                            className="mundl113 tw-rounded-full tw-h-10 tw-w-10 tw-mr-2 tw-object-cover"
                          />
                        )}
                        <span
                          className={`mundl114 tw-inline-block tw-w-full tw-break-words tw-text-xl tw-rounded-lg tw-px-4 tw-py-2 tw-my-1 ${message.sender === "user"
                            ? "tw-text-gray-800 tw-text-left"
                            : "tw-text-[#0000A2] tw-text-left"
                            }`}
                          dangerouslySetInnerHTML={createMarkup(message.text)}
                          style={{
                            backgroundColor:
                              message.sender === "user" ? "#E5E7EB" : "#FFFFFF",
                            fontFamily: "sans-serif",
                          }}
                        />
                        <br />
                        {message.sender === "user" && (
                          <img
                            src={user_image}
                            alt="User"
                            className="mundl115 tw-rounded-full tw-h-8 tw-w-8 tw-ml-2"
                          />
                        )}
                      </div>
                    ))}
                    <div ref={messagesEndRef} />
                  </div>
                  <div className="mundl116 tw-card-footer tw-bg-white tw-pb-3 tw-pl-3 tw-pr-3">
                    {isListening && renderAudioAnimation()}
                    <form onSubmit={handleSendMessage} className="mundl117 tw-flex tw-items-center tw-w-full">
                      <div className="tw-flex tw-w-full tw-space-x-2">
                        <input
                          ref={inputRef}
                          type="text"
                          className="mundl118 tw-shadow tw-appearance-none  tw-border tw-border-gray-300 tw-rounded tw-py-2 tw-px-1 tw-text-base tw-flex-grow tw-leading-tight focus:tw-outline-none focus:tw-shadow-outline focus:tw-border-[#0000A2]"
                          value={userInput}
                          onChange={(e) => setUserInput(e.target.value.slice(0, 200))}
                          onFocus={() => setShowTooltip(true)}
                          onBlur={() => setShowTooltip(false)}
                          maxLength="200"
                          placeholder="Tippen Sie hier Ihre Nachricht ein..."
                          style={{ fontFamily: "sans-serif" }}
                        />

                        <button
                          type="button"
                          className="mundl120 tw-font-bold tw-py-2 tw-px-2 tw-text-base md:tw-text-xl tw-rounded-full tw-h-12 md:tw-h-14 tw-w-12 md:tw-w-14 tw-flex tw-items-center tw-justify-center flex-shrink-0"
                          onClick={toggleListening}
                          style={{
                            backgroundColor: "#2edfba",
                            color: "white",
                            fontFamily: "sans-serif",
                          }}
                          disabled={isLoading}
                        >
                          <MdKeyboardVoice size={24} />
                        </button>
                        <button
                          type="submit"
                          className="mundl119 tw-font-bold tw-py-2 tw-px-2 tw-text-base md:tw-text-xl tw-rounded-full tw-h-12 md:tw-h-14 tw-w-12 md:tw-w-14 tw-flex tw-items-center tw-justify-center flex-shrink-0"
                          style={{
                            backgroundColor: "#0000A2",
                            color: "white",
                            fontFamily: "sans-serif",
                          }}
                          disabled={isLoading}
                        >
                          <AiOutlineSend size={24} />
                        </button>
                      </div>

                      {showTooltip && (
                        <div
                          className={`tw-text-lg ${userInput.length === 200
                            ? "tw-text-red-950"
                            : "tw-text-[#0000A2]"
                            }`}
                          style={{
                            position: "absolute",
                            marginTop: "-30px",
                            fontFamily: "sans-serif",
                          }}
                        >{`${userInput.length}/200`}</div>
                      )}
                    </form>
                    {isLoading && (
                      <div className="mundl120 tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-2">
                        <div className="mundl121 tw-flex tw-justify-center tw-items-center">
                          <div
                            className="mundl122 tw-spinner-border tw-animate-spin tw-inline-block tw-w-8 tw-h-8 tw-border-4 tw-rounded-full"
                            style={{ borderColor: "#0000A2" }}
                            role="status"
                          >
                            <span className="mundl123 tw-hidden">Loading...</span>
                          </div>
                        </div>
                        <p
                          className="mundl124 tw-text-xl tw-ml-2 tw-text-center"
                          style={{ fontFamily: "sans-serif" }}
                        >
                          Bitte haben Sie ein bisschen Geduld...
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {!isVisible && (
          <div className="tw-fixed tw-bottom-0 tw-w-full tw-text-center tw-py-2 tw-bg-white tw-shadow-md ">
            <a className="tw-text-xl tw-text-[#0000A2]" href="https://www.mlconsult.com/impressum.html" target="_blank" rel="noopener noreferrer">Impressum</a>
          </div>)}
      </div>
    </>
  );
};

export default ChatbotTest;
